<template>
  <div class="">
    <div class="row mb-1">
      <div class="col-md-5">
        <v-select
          id="status"
          class="w-100"
          v-model="selectedBoard"
          :options="optionsBoards"
          :reduce="(value) => value.id"
          @input="getBoardInformation"
          label="name"
        />
      </div>
      <div class="col-md-12 mt-2">
        <b-skeleton-wrapper :loading="loading" class="d-flex">
          <BoardFilter @searchFilter="searchFilter" />
        </b-skeleton-wrapper>
      </div>
    </div>
    <section>
      <b-skeleton-wrapper :loading="loading" class="d-flex">
        <template #loading>
          <b-skeleton width="15%" class="loading-overlay"></b-skeleton>
          <b-skeleton width="15%" class="loading-overlay mx-2"></b-skeleton>
          <b-skeleton width="15%" class="loading-overlay"></b-skeleton>
        </template>

        <b-row class="flex-nowrap scrolling-wrapper align-items-center">
          <b-col
            v-for="column in boardColums"
            :key="column.id"
            md="12"
            xl="2"
            :style="{ padding: '0 5px' }"
          >
            <div class="header-board">
              <ColumnBoard
                :infoTitle="{ title: column.name, color: column.color }"
              />
              <b-button
                block
                variant="primary"
                size="15px"
                class="padding-button"
                @click="addNewCardBoard(column.id)"
              >
                <feather-icon icon="PlusIcon" size="16" class="mr-25" />
                <span>Add Card</span>
              </b-button>
            </div>
            <b-card
              body-class="m-0 p-0 overflow-auto"
              :style="{ 'background-color': column.color, height: '73vh;' }"
            >
              <draggable
                v-model="column.cards"
                class="board"
                group="boards"
                :disabled="isDetailOpen"
                :sort="true"
                @change="moveCard($event, column.id)"
              >
                <transition-group name="fade" tag="div" class="cards">
                  <div v-for="card in column.cards" :key="card.id" class="mb-1">
                    <CardBoard
                      :body="card"
                      @noMoveCard="onChangeOpenDetail"
                      @closeSidebarCardBoard="closeCardNewBoard"
                    />
                  </div>
                </transition-group>
              </draggable>
            </b-card>
          </b-col>
          <b-button
            variant="outline-primary"
            class="btn-icon rounded-circle ml-3"
            @click="isAddNewColumnSidebarActive = true"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
        </b-row>
      </b-skeleton-wrapper>
    </section>
    <CardNewModalBoard
      v-if="isAddNewCardBoard"
      :columnId="columnId"
      :boardId="boardId"
      @closeModalCardBoard="closeCardNewBoard"
      @notificationCheck="notificationCheck"
    />
    <ColumnAdd
      v-if="isAddNewColumnSidebarActive"
      :is-add-new-column-sidebar-active.sync="isAddNewColumnSidebarActive"
      :defaultBoard="boardId"
      @createColumn="createColumn"
    />
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import {
  BCard,
  BBadge,
  BSkeletonWrapper,
  BSkeleton,
  BButton,
  BRow,
  BCol,
} from "bootstrap-vue";
import vSelect from "vue-select";

import axiosB from "@/services/board";
import axiosCard from "@/services/board/card";

import ToastNotification from "@/components/ToastNotification";
import CardBoard from "./components/CardBoard.vue";
import ColumnBoard from "./components/ColumnBoard.vue";
import CardNewModalBoard from "./components/CardNewModalBoard.vue";
import ColumnAdd from "@/views/admin/crm/columns/ColumnAdd.vue";
import BoardFilter from "@/views/crm/boards/BoardFilter.vue";

export default {
  components: {
    draggable,
    BCard,
    BBadge,
    BSkeletonWrapper,
    BSkeleton,
    BButton,
    BRow,
    BCol,
    ToastNotification,
    CardBoard,
    ColumnBoard,
    CardNewModalBoard,
    ColumnAdd,
    BoardFilter,

    vSelect,
  },
  data() {
    return {
      selectedBoard: null,
      selectedCard: null,
      loading: true,
      isDetailOpen: false,
      optionsBoards: [],
      boardInformation: null,
      boardColums: [],
      isAddNewCardBoard: false,
      columnId: null,
      boardId: null,
      subscribeSocket: false,

      isAddNewColumnSidebarActive: false,
    };
  },
  mounted() {
    this.getBoards();
  },
  destroyed() {
    this.$pusher.unsubscribe(`crm.${this.boardId}`);
  },
  methods: {
    suscribeSockets() {
      this.subscribeSocket = true;
      const channel = this.$pusher.subscribe(`crm.${this.boardId}`);
      channel.bind("crmEvent", ({ event }) => {
        if (event.type === "card_move" || event.type === "card_create") {
          this.getBoardInformation(true);
        }
      });
    },
    onChangeOpenDetail(value) {
      this.isDetailOpen = value;
    },
    getBoards() {
      axiosB.boardList().then(({ registro }) => {
        this.optionsBoards = registro;
        this.selectedBoard = registro[0].id;
        this.getBoardInformation();
      });
    },
    getBoardInformation(cardNew = false) {
      !cardNew ? (this.loading = true) : null;
      axiosB.boardGetAll(this.selectedBoard).then(({ registro }) => {
        this.boardInformation = registro;
        this.boardId = registro.id;
        if (!this.subscribeSocket) {
          this.suscribeSockets();
        }
        //ordenar por order
        this.boardColums = registro.columns.sort((a, b) => a.order - b.order);
        this.loading = false;
      });
    },
    addNewCardBoard(columnId) {
      this.columnId = columnId;
      this.isAddNewCardBoard = true;
    },
    closeCardNewBoard() {
      this.columnId = null;
      this.isAddNewCardBoard = false;
      this.getBoardInformation(true);
    },
    notificationCheck({ type, message }) {
      this.$refs.toast[type](message);
    },
    moveCard(event, columnId) {
      if (event.added) {
        const cardId = event.added.element.id;
        const data = {
          action: "card_move",
          column_id: columnId,
          board_id: this.boardId,
        };
        console.log(data);
        axiosCard.cardActions(cardId, data).then(() => {});
      }
    },
    createColumn(status) {
      console.log(status);
      if (status) {
        this.isAddNewColumnSidebarActive = false;
        this.getBoardInformation(true);
      }
    },
    searchFilter(value){
      const filter = {
        ...value,
        board_id: this.boardId
      }
      axiosB.boardFilter(this.boardId, filter).then(({registro}) => {
        const [dataBoard] = registro.data;
        if (!this.subscribeSocket) {
          this.suscribeSockets();
        }
        //ordenar por order
        this.boardColums = dataBoard.columns.sort((a, b) => a.order - b.order);
        this.loading = false;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.board {
  padding: 10px;
  min-height: 200px;
  /* quitar el seleccionar */
  -webkit-touch-callout: none; /* iOS Safari */
}
.cards {
  height: 63vh;
  overflow: auto;
}

/* crear forma de flecha hacia la derecha y siempre debe de estar arriba */
.header-board {
  position: sticky;
  z-index: 10;
  margin-bottom: 5px;
}

.padding-button {
  padding: 0.5rem;
}

.loading-overlay {
  height: 70vh;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* @media (min-width: 768px) {
  .col-lg-2 {
    flex: 0 0 18.666667% !important;
    max-width: 18.666667% !important;
  }
}
 */
.scrolling-wrapper {
  overflow-x: auto;
}
</style>
