import { userAxios } from '@/services'

const boardList = async () => {
    try {
        return await userAxios.get(`boards/show/all`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const boardGetAll = async (id) => {
    try {
        return await userAxios.get(`boards/get/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const boardGet = async (id) => {
    try {
        return await userAxios.get(`boards/board_only/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const boardFilter = async (id, data) => {
    try {
        return await userAxios.post(`boards/filter/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    boardList,
    boardGetAll,
    boardGet,
    boardFilter,
}