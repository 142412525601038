import { userAxios } from '@/services'

const columnList = async (perPage) => {
    try {
        return await userAxios.get(`column/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const columnCreate = async (data) => {
    try {
        return await userAxios.post('column', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const columnUpdate = async (id, data) => {
    try {
        return await userAxios.put(`column/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const columnDelete = async (id) => {
    try {
        return await userAxios.delete(`column/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const columnPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`column/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const columnFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`column/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const columnFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`column/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
export default {
    columnList,
    columnCreate,
    columnUpdate,
    columnDelete,
    columnPagination,
    columnFilter,
    columnFilterPagination
}