import { userAxios } from '@/services'

const cardList = async (perPage) => {
    try {
        return await userAxios.get(`card/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const cardGet = async (id) => {
    try {
        return await userAxios.get(`card/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const cardCreate = async (data) => {
    try {
        return await userAxios.post('card', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const cardUpdate = async (id, data) => {
    try {
        return await userAxios.put(`card/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const cardActions = async (id, data) => {
    try {
        return await userAxios.post(`card/actions/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    cardList,
    cardGet,
    cardCreate,
    cardUpdate,
    cardActions
}