<template>
  <b-modal
    id="modal-document-add"
    hide-header
    hide-footer
    content-class="rounded-lg"
    @shown="onShow"
  >
    <header>
      <h4 class="font-weight-bold">Create new File</h4>
      <hr />
    </header>
    <validation-observer ref="refFormObserver">
      <b-form @submit.prevent="onSubmit">
        <div class="m-2">
          <validation-provider
            #default="{ errors }"
            name="Title"
            rules="required"
          >
            <b-form-group label="Title" label-for="name">
              <b-form-input v-model="newDocument.name" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="File"
            rules="required"
          >
            <b-form-group label="File" label-for="file">
              <b-form-file
                v-model="fileDocument"
                @change="onFileChange($event)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <div class="d-flex justify-content-center mt-2">
            <b-button variant="primary" type="submit" :disabled="loading">
              <span v-if="!loading">{{ $t("Add") }}</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BButton,
  BFormGroup,
  BFormInput,
  BFormFile,
  VBModal,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import axiosEC from "@/services/external-settings/images";
import axiosCard from "@/services/board/card";

import { required } from "@validations";

import SpinnerLoading from "@/components/SpinnerLoading";
export default {
  components: {
    BModal,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,

    SpinnerLoading,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    selectedCard: {
      type: Object,
      required: true,
    },
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      loading: false,
      fileDocument: null,
      newDocument: {
        name: "",
        type: "",
        date: new Date().toISOString().split("T").join(" ").split(".")[0],
        userId: localStorage.getItem("userData").id,
      },
      fileResource: null,
    };
  },
  methods: {
    async onSubmit() {
      const isCompleted = this.$refs.refFormObserver.validate();
      if (isCompleted) {
        this.loading = true;
        const fileUrl = await this.uploadPhoto(this.fileResource);
        this.newDocument = {
          ...this.newDocument,
          id: fileUrl.split("/")[5].split(".")[0],
          path: fileUrl,
          type: fileUrl.split(".").pop(),
          size: `${this.fileDocument.size / 1000} KB`,
        };
        axiosCard
          .cardActions(this.selectedCard.id, {
            action: "card_add_attachment",
            json_attachment: this.newDocument,
          })
          .then(({ registro: { data } }) => {
            this.loading = false;
            this.selectedCard.attachments.attachments = data.attachments;
            this.onHide();
          });
        this.changePhoto = false;
      }
    },
    onHide() {
      // $bvModal.hide('bv-modal-example')
      this.$bvModal.hide("modal-document-add");
    },
    onShow() {
      this.newDocument = {
        name: "",
        type: "",
        date: new Date().toISOString().split("T").join(" ").split(".")[0],
        userId: JSON.parse(localStorage.getItem("userData")).id,
      };
      this.fileDocument = null;
      this.fileResource = null;
      this.changePhoto = false;
    },
    async uploadPhoto(file) {
      const formData = new FormData();
      formData.append("type", "CARD_DOCUMENT");
      formData.append("file", file);
      const { dir_img } = await axiosEC.createImage(formData);
      return dir_img;
    },
    onFileChange(event) {
      if (event.target.files[0]) {
        this.fileResource = event.target.files[0];
        this.changePhoto = true;
      } else {
        this.fileResource = null;
        this.changePhoto = false;
      }
      //returns an array of files even though multiple not used
    },
  },
};
</script>

<style scoped>
#modal-document-add___BV_modal_outer_ {
  z-index: 2000 !important;
}
</style>
