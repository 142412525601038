<template>
  <b-popover
    ref="popover"
    target="popover-reactive-1"
    triggers="click"
    :show.sync="popoverShow"
    placement="bottom"
    @shown="onShown"
    @hidden="onHidden"
  >
    <template v-slot:title>
      <div class="d-flex justify-content-between align-items-center">
        <span>Labels</span>
        <b-button
          class="close"
          variant="transparent"
          aria-label="Close"
          @click="onClose"
        >
          <span class="d-inline-block text-white" aria-hidden="true"
            >&times;</span
          >
        </b-button>
      </div>
    </template>

    <div>
      <v-select
        v-model="label_id"
        class="w-100"
        ref="labelRef"
        :options="labelsOptions"
        :reduce="(value) => value.id"
        label="name"
        multiple
        @input="addLabelToCard"
      />
    </div>
  </b-popover>
</template>

<script>
import { BPopover, BButton, BFormInput, BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";

import axiosLabel from "@/services/admin/crm/labels";
import axiosCard from "@/services/board/card";
export default {
  components: {
    BPopover,
    BButton,
    BFormInput,
    BFormGroup,

    vSelect,
  },
  props: {
    selectedCard: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.getLabels();
  },
  data() {
    return {
      popoverShow: false,
      label_id: [],
      labelsOptions: [],
    };
  },
  methods: {
    getLabels() {
      axiosLabel
        .labelList(20)
        .then(({ data }) => {
          this.labelsOptions = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onShown() {
      this.getLabels();
      this.label_id = this.selectedCard.labels
    },
    onHidden() {
      this.popoverShow = false;
    },
    onClose() {
      this.popoverShow = false;
    },
    addLabelToCard(){
      // buscar en labelsOptions el id que coincida con los ids de label_id
      const filter = this.labelsOptions.filter((label) => {
        return this.label_id.includes(label.id)
      })
      const data = {
        labels: this.label_id
      }
      axiosCard.cardUpdate(this.selectedCard.id, data).then(({ data }) => {
        this.selectedCard.labels = filter
      });
    },
  },
};
</script>

<style scoped>
.popover.b-popover.bs-popover-bottom {
  z-index: 2000 !important;
  width: 100% !important;
}
</style>
