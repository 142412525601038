import { userAxios } from '@/services'

const boardList = async (perPage) => {
    try {
        return await userAxios.get(`boards/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const boardCreate = async (data) => {
    try {
        return await userAxios.post('boards', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const boardUpdate = async (id, data) => {
    try {
        return await userAxios.put(`boards/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const boardDelete = async (id) => {
    try {
        return await userAxios.delete(`boards/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const boardPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`boards/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const boardFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`boards/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const boardFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`boards/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
export default {
    boardList,
    boardCreate,
    boardUpdate,
    boardDelete,
    boardPagination,
    boardFilter,
    boardFilterPagination
}