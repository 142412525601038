<template>
  <b-modal
    id="modal-message-add"
    v-model="modalMessage"
    hide-header
    hide-footer
    content-class="rounded-lg"
    @shown="onShow"
    @hide="onHide"
  >
    <header>
      <h4 class="font-weight-bold">Create Message</h4>
      <hr />
    </header>
    <validation-observer ref="refFormObserver">
      <b-form @submit.prevent="onSubmit">
        <div class="m-2">
          <validation-provider
            #default="{ errors }"
            name="Phone"
            rules="required"
          >
            <b-form-group label="Phone" label-for="phone">
              <b-form-input
                disabled
                :value="formatPhone(selectedCard.patient.phone)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="Message"
            rules="required"
          >
            <b-form-group label="Message" label-for="message">
              <b-form-textarea
                id="textarea-no-resize"
                placeholder="Escribe un comentario.."
                v-model="message"
                rows="3"
                no-resize
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <div class="d-flex justify-content-center mt-2">
            <b-button variant="primary" type="submit" :disabled="loading">
              <span v-if="!loading">{{ $t("Add") }}</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import {
  BModal,
  BForm,
  BButton,
  BFormGroup,
  BFormInput,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import axiosCard from "@/services/board/card";

import { required } from "@validations";

import SpinnerLoading from "@/components/SpinnerLoading";
import { formatPhone } from "@/mixins/formatPhone.js";

export default {
  components: {
    BModal,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,

    ValidationProvider,
    ValidationObserver,

    SpinnerLoading,
  },
  props: {
    selectedCard: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formatPhone,
      message: "",
      loading: false,
      modalMessage: true,
    };
  },
  methods: {
    onSubmit() {
      this.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          this.loading = true;
          const actions = {
            action: "card_send_sms",
            message: this.message,
          };
          axiosCard
            .cardActions(this.selectedCard.id, actions)
            .then((response) => {
              this.loading = false;
              this.$bvModal.hide("modal-message-add");
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      });
    },
    onHide() {
      // $bvModal.hide('bv-modal-example')
      this.$emit("closeCardNewBoard", false);
    },
    onShow() {
      this.message = "";
    },
  },
};
</script>
<style scoped>
#modal-message-add___BV_modal_outer_ {
  z-index: 2000 !important;
}
</style>
