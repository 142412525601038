<template>
  <div>
    <b-modal id="modal-add-card" hide-footer hide-header size="lg" centered
      ignore-enforce-focus-selector="#add-new-consult-sidebar" v-model="isAddNewCardBoard" @hide="onHide">
      <div class="mb-1 mt-1 text-center">
        <h2 class="mb-1">New Card</h2>
        <div class="text-muted">
          <span>Card will be added to </span>
          <span class="font-weight-bolder">New Column</span>
        </div>
      </div>
      <validation-observer ref="refFormObserver">
        <b-form @submit.prevent="onSubmit">
          <div class="m-2">
            <validation-provider #default="{ errors }" name="Title" rules="required" class="w-100">
              <b-form-group label="Title" label-for="title">
                <b-form-input v-model="newCardForm.title" />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <div class="d-flex w-100 justify-content-between">
              <!-- date -->
              <validation-provider #default="{ errors }" name="DOB" rules="required" class="w-100">
                <b-form-group label="DOB" label-for="date">
                  <div class="d-flex flex-column">
                    <flat-pickr v-model="date_birth" :config="config" class="form-control" placeholder="MM-DD-YYYY"
                      @blur="getPatients" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                </b-form-group>
              </validation-provider>

              <!-- patient -->
              <validation-provider #default="{ errors }" name="Patient" rules="required" class="w-100 ml-2">
                <b-form-group label="Patient" label-for="patient">
                  <v-select v-model="newCardForm.patient_id" class="w-100" :options="patientOptions"
                    :reduce="(value) => value.id" label="name" @search="searchPatient">
                    <template #no-options="{ search, searching }">
                      <div v-if="searching && search">
                        {{ search }}
                        <b-button variant="primary" @click="isAddNewPatient = true">+</b-button>
                      </div>
                    </template>
                    <template slot="option" slot-scope="option">
                      <span> {{ option.name }} {{ option.lastname }}</span>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <span> {{ option.name }} {{ option.lastname }}</span>
                    </template>
                  </v-select>
                  <PatientAdd v-if="isAddNewPatient" :isOnlyCreatePatient="true" :isScanQrPatient="null"
                    @closeModalPatient="isAddNewPatient = false" @createPatient="createPatient" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </div>

            <!-- Descripcion -->
            <validation-provider #default="{ errors }" name="Description" rules="required">
              <b-form-group label="Description" label-for="description">
                <textarea name="description" id="description" class="form-control" v-model="newCardForm.description">
    </textarea>

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <div class="d-flex justify-content-center mt-2">
              <b-button type="button" class="mr-1" variant="outline-danger" @click="onHide">
                {{ $t("Cancel") }}
              </b-button>
              <b-button variant="primary" type="submit" :disabled="loading">
                <span v-if="!loading">{{ $t("Add") }}</span>
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BButton,
  BAvatar,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import SpinnerLoading from "@/components/SpinnerLoading";
import ToastNotification from "@/components/ToastNotification";

import SelectUser from "@/components/SelectUser";
import PatientAdd from "@/views/patients/patients-actions/PatientAdd.vue";

import { notificationCheck } from "@/mixins/NotificationSetup";

import axiosP from "@/services/patient";
import axiosU from "@/services/admin/user";
import axiosC from "@/services/board/card";
import { fromConfig } from "@/mixins/configFormatCal";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BButton,
    BAvatar,

    SelectUser,
    flatPickr,
    SpinnerLoading,
    ToastNotification,

    ValidationProvider,
    ValidationObserver,

    vSelect,
    PatientAdd,
  },
  props: {
    columnId: {
      type: Number,
      required: true,
    },
    boardId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isAddNewCardBoard: true,
      loading: false,
      newCardForm: {
        title: "",
        color: "#FFF",
        date: "",
        order: 1,
        attachments: null,
        tracebility: "",
        description: "",
        agent_id: null,
        label_id: null,
        card_status_id: null,
        patient_id: null,
        column_id: this.columnId,
        board_id: this.boardId,
      },
      config: fromConfig({ dateFormat: "m-d-Y", isStatic: true }),
      date_birth: "",
      required,

      patientOptions: [],

      isAddNewPatient: false,
      clearTimeoutBuscador: "",
    };
  },
  methods: {
    async onSubmit() {
      const check = await this.$refs.refFormObserver.validate();
      if (check) {
        this.loading = true;
        await axiosC
          .cardCreate(this.newCardForm)
          .then((res) => {
            const { type, message } = notificationCheck(res);
            this.$emit("notificationCheck", {
              type,
              message,
            });
            this.$emit("closeModalCardBoard", false);
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.$emit("notificationCheck", {
              type,
              message,
            });
          });
      }
    },
    onHide() {
      this.$emit("closeModalCardBoard", false);
    },
    getPatients() {
      const data = {
        date_birth: this.date_birth,
      };
      axiosP.patientFilter(20, data).then(({ data }) => {
        this.patientOptions = data;
      });
    },
    searchPatient(search, loading) {
      if (search.length) {
        loading(true);
        const data = {
          name: search,
          date_birth: this.date_birth
        };
        clearTimeout(this.clearTimeoutBuscador);
        this.clearTimeoutBuscador = setTimeout(() => {
          axiosP.patientFilter(20, data).then(({ data }) => {
            this.patientOptions = data;
            loading(false);
          });
        }, 400);
      }
    },
    getPatientOnly(id) {
      axiosP.patientDetail(id).then(({ registro }) => {
        this.newCardForm.patient_id = registro.id;
        this.patientOptions.push(registro);
      });
    },
    createPatient({ type, message, idUser }) {
      console.log(type, message, idUser);
      this.getPatientOnly(idUser);
      this.notificationCheckToast({ type, message });

      if (type === "ERROR") {
        this.$swal({
          title: "Error!",
          text: message,
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      this.isAddNewPatient = false;
    },
    notificationCheckToast({ type, message }) {
      this.$refs.toast[type](message);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.flatpickr-wrapper {
  width: 100% !important;
}
</style>
