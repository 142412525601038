<template>
  <div
    class="title-board text-center margin-title"
    :style="{ 'background-color': infoTitle.color }"
  >
    <h4 class="text-white mb-0">{{ infoTitle.title }}</h4>
  </div>
</template>

<script>
export default {
  props: {
    infoTitle: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped>
.title-board {
  clip-path: polygon(95% 0, 100% 50%, 95% 99%, 0% 100%, 6% 49%, 0% 0%);
  padding: 7px;
}
.margin-title{
  margin-bottom: 6px;
}
</style>
