<template>
  <div>
    <b-row>
      <b-col class="text-center">
        <b-card body-class="pt-0">
          <b-card-body>
            <div class="d-flex justify-content-between w-100">
              <div
                class="w-25 mx-1"
                v-for="(column, index) in columnsList"
                :key="index"
              >
                <b-button
                  :style="{
                    'background-color':
                      column.id === selectedCard.column_id
                        ? `${column.color} !important`
                        : '#bbb !important',
                  }"
                  class="w-100 step-button border-0"
                  @click="moveCard(column.id)"
                >
                  {{ column.name }}
                </b-button>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-card body-class="shadow">
          <b-card-body>
            <b-card-title class="text-center">
              <h2>
                {{ selectedCard.title }}
              </h2>
            </b-card-title>
            <div>
              <p>
                {{ selectedCard.description }}
              </p>
              <div class="d-flex">
                <b-avatar :src="selectedCard.agent.avatar" />
                <div class="d-flex flex-column ml-1">
                  <span class="text-muted">Agregado por:</span>
                  {{ selectedCard.agent.name }}
                  {{ selectedCard.agent.lastname }}
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
        <b-card body-class="shadow p-0">
          <h6 class="pl-2 mt-1">Patient</h6>
          <hr />
          <b-card-body>
            <div>
              <h3>
                {{ selectedCard.patient.name }}
                {{ selectedCard.patient.lastname }}
              </h3>
              <small class="text-muted">
                {{ formatPhone(selectedCard.patient.phone) }} /
                {{ selectedCard.patient.email }}
              </small>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <ActivityCard :selectedCard="selectedCard" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BCardText,
  BButton,
  BAvatar,
} from "bootstrap-vue";
import ColumnBoard from "../ColumnBoard.vue";
import ActivityCard from "./ActivityCard.vue";

import axiosBoard from "@/services/board";
import axiosCard from "@/services/board/card";

import { formatPhone } from "@/mixins/formatPhone.js";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BCardText,
    BButton,
    BAvatar,

    ColumnBoard,
    ActivityCard,
  },
  mounted() {
    this.getColumns();
  },
  props: {
    selectedCard: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formatPhone,
      columnsList: [],
    };
  },
  methods: {
    getColumns() {
      const boardId = this.selectedCard.column.board_id;
      axiosBoard.boardGet(boardId).then(({ registro: { columns } }) => {
        this.columnsList = columns.sort((a, b) => a.order - b.order);
      });
    },
    moveCard(columnId) {
      const data = {
        action: "card_move",
        column_id: columnId,
      };
      console.log(data);
      axiosCard.cardActions(this.selectedCard.id, data).then(() => {
        this.selectedCard.column_id = columnId;
      });
    },
  },
};
</script>

<style scoped>
.step-button {
  clip-path: polygon(95% 0, 100% 50%, 95% 99%, 0% 100%, 6% 49%, 0% 0%);
}
</style>
