import { userAxios } from '@/services'

const labelList = async (perPage) => {
    try {
        return await userAxios.get(`labels/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const labelCreate = async (data) => {
    try {
        return await userAxios.post('labels', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const labelUpdate = async (id, data) => {
    try {
        return await userAxios.put(`labels/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const labelDelete = async (id) => {
    try {
        return await userAxios.delete(`labels/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const labelPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`labels/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const labelFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`labels/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const labelFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`labels/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
export default {
    labelList,
    labelCreate,
    labelUpdate,
    labelDelete,
    labelPagination,
    labelFilter,
    labelFilterPagination
}