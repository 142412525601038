<template>
  <div>
    <b-row>
      <b-col md="12" lg="8">
        <div class="d-flex justify-content-end">
          <b-button v-b-modal.modal-document-add variant="primary" class="btn-icon rounded-circle">
            +
          </b-button>
        </div>
        <b-table
          responsive="sm"
          :items="attachments.attachments"
          :fields="fields"
          class="table-documents"
        >
          <template #cell(select)="row">
            <b-form-checkbox
              v-model="row.detailsShowing"
              class="vs-checkbox-con"
              @change="selectedFiles(row)"
            >
            </b-form-checkbox>
          </template>
          <template #row-details="">
            <span></span>
          </template>
          <template v-slot:cell(name)="data">
            <div class="d-flex align-items-center">
              <b-img width="30" :src="getImageUrl(data.item.type)"></b-img>
              <b-card-text class="text-center ml-1">
                {{ data.item.name }}
              </b-card-text>
            </div>
          </template>
        </b-table>
      </b-col>
      <b-col md="12" lg="4">
        <div v-if="fileSelected">
          <h4 class="font-weight-bold">File details</h4>
          <img
            v-if="
              fileSelected.type === 'png' ||
              fileSelected.type === 'jpg' ||
              fileSelected.type === 'jpeg'
            "
            :src="fileSelected.path"
            alt=""
            width="100%"
          />
          <object
            v-else
            width="100%"
            height="600"
            type="application/pdf"
            :data="fileSelected.path"
          >
            <p>
              Insert your error message here, if the PDF cannot be displayed.
            </p>
          </object>
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <span class="font-weight-bold">File name</span>
              </th>
              <td class="pb-50 text-right">{{ fileSelected.name }}</td>
            </tr>
            <tr>
              <th class="pb-50">
                <span class="font-weight-bold">File Type</span>
              </th>
              <td class="pb-50 text-right">{{ fileSelected.type }}</td>
            </tr>
            <tr>
              <th class="pb-50">
                <span class="font-weight-bold">File Size</span>
              </th>
              <td class="pb-50 text-right">{{ fileSelected.size }}</td>
            </tr>
            <tr>
              <th class="pb-50">
                <span class="font-weight-bold">Date created</span>
              </th>
              <td class="pb-50 text-right">{{ fileSelected.date }}</td>
            </tr>
          </table>
        </div>
      </b-col>
    </b-row>
    <DocumentAdd :selectedCard="selectedCard"/>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BImg,
  BFormCheckbox,
  BModal,
  BButton,
} from "bootstrap-vue";
import DocumentAdd from "./components/DocumentAdd.vue";
export default {
  components: {
    BRow,
    BCol,
    BTable,
    BCard,
    BCardText,
    BImg,
    BFormCheckbox,
    BModal,
    BButton,

    DocumentAdd,
  },
  props: {
    selectedCard: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      attachments: this.selectedCard.attachments,
      fields: [
        {
          key: "select",
          label: "select",
          class: "mx-0 px-0",
          headerClass: "table-documents",
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "size",
          label: "File size",
          sortable: true,
        },
        {
          key: "date",
          label: "Date",
        },
        {
          key: "user_name",
          label: "User",
        },
      ],
      fileSelected: null,
    };
  },
  methods: {
    getImageUrl(file) {
      if (file === "txt") {
        return require("@/assets/images/icons/txt.png");
      }
      if (file === "pdf") {
        return require("@/assets/images/icons/pdf.png");
      }
      if (file === "png" || file === "jpg" || file === "jpeg") {
        return require("@/assets/images/icons/jpg.png");
      }
      if(file === "doc" || file === "docx") {
        return require("@/assets/images/icons/doc.png");
      }
      if(file === "xls" || file === "xlsx") {
        return require("@/assets/images/icons/xls.png");
      }
    },
    selectedFiles(file) {
      file.toggleDetails();
      if (!file.detailsShowing) {
        this.fileSelected = null;
        return;
      }
      this.fileSelected = file.item;
      this.attachments.attachments.forEach((item) => {
        if (item.id !== file.item.id) {
          item._showDetails = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.table-documents {
  background-color: #f9fbfc;
}
</style>
