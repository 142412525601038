var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-add-card","hide-footer":"","hide-header":"","size":"lg","centered":"","ignore-enforce-focus-selector":"#add-new-consult-sidebar"},on:{"hide":_vm.onHide},model:{value:(_vm.isAddNewCardBoard),callback:function ($$v) {_vm.isAddNewCardBoard=$$v},expression:"isAddNewCardBoard"}},[_c('div',{staticClass:"mb-1 mt-1 text-center"},[_c('h2',{staticClass:"mb-1"},[_vm._v("New Card")]),_c('div',{staticClass:"text-muted"},[_c('span',[_vm._v("Card will be added to ")]),_c('span',{staticClass:"font-weight-bolder"},[_vm._v("New Column")])])]),_c('validation-observer',{ref:"refFormObserver"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"m-2"},[_c('validation-provider',{staticClass:"w-100",attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Title","label-for":"title"}},[_c('b-form-input',{model:{value:(_vm.newCardForm.title),callback:function ($$v) {_vm.$set(_vm.newCardForm, "title", $$v)},expression:"newCardForm.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('div',{staticClass:"d-flex w-100 justify-content-between"},[_c('validation-provider',{staticClass:"w-100",attrs:{"name":"DOB","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"DOB","label-for":"date"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.config,"placeholder":"MM-DD-YYYY"},on:{"blur":_vm.getPatients},model:{value:(_vm.date_birth),callback:function ($$v) {_vm.date_birth=$$v},expression:"date_birth"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)])]}}])}),_c('validation-provider',{staticClass:"w-100 ml-2",attrs:{"name":"Patient","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Patient","label-for":"patient"}},[_c('v-select',{staticClass:"w-100",attrs:{"options":_vm.patientOptions,"reduce":function (value) { return value.id; },"label":"name"},on:{"search":_vm.searchPatient},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching && search)?_c('div',[_vm._v(" "+_vm._s(search)+" "),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){_vm.isAddNewPatient = true}}},[_vm._v("+")])],1):_vm._e()]}},{key:"option",fn:function(option){return [_c('span',[_vm._v(" "+_vm._s(option.name)+" "+_vm._s(option.lastname))])]}},{key:"selected-option",fn:function(option){return [_c('span',[_vm._v(" "+_vm._s(option.name)+" "+_vm._s(option.lastname))])]}}],null,true),model:{value:(_vm.newCardForm.patient_id),callback:function ($$v) {_vm.$set(_vm.newCardForm, "patient_id", $$v)},expression:"newCardForm.patient_id"}}),(_vm.isAddNewPatient)?_c('PatientAdd',{attrs:{"isOnlyCreatePatient":true,"isScanQrPatient":null},on:{"closeModalPatient":function($event){_vm.isAddNewPatient = false},"createPatient":_vm.createPatient}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.newCardForm.description),expression:"newCardForm.description"}],staticClass:"form-control",attrs:{"name":"description","id":"description"},domProps:{"value":(_vm.newCardForm.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newCardForm, "description", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('div',{staticClass:"d-flex justify-content-center mt-2"},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"button","variant":"outline-danger"},on:{"click":_vm.onHide}},[_vm._v(" "+_vm._s(_vm.$t("Cancel"))+" ")]),_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":_vm.loading}},[(!_vm.loading)?_c('span',[_vm._v(_vm._s(_vm.$t("Add")))]):_c('span',[_c('SpinnerLoading')],1)])],1)],1)])],1)],1),_c('ToastNotification',{ref:"toast"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }