<template>
  <div>
    <div class="mb-1">
      <b-form-textarea
        id="textarea-no-resize"
        placeholder="Escribe un comentario.."
        v-model="comment"
        rows="3"
        no-resize
      />
      <div class="d-flex justify-content-end">
        <!-- <div>
          <feather-icon
            class="cursor-pointer text-primary margin-icon"
            icon="ImageIcon"
            size="18"
          />
        </div> -->
        <b-button variant="primary" size="sm" class="mt-1" @click="sendComment">
          <feather-icon
            class="cursor-pointer text-white margin-icon"
            icon="SendIcon"
            size="15"
          />
          Enviar
        </b-button>
      </div>
    </div>
    <b-card
      class="my-0"
      body-class=" rounded-lg py-0"
      v-for="(comment, index) in sortComments"
      :key="index"
    >
      <b-media no-body>
        <b-media-aside class="mr-75">
          <b-avatar :src="comment.user.avatar || comment.avatar" size="38" />
        </b-media-aside>
        <b-media-body>
          <div class="d-flex justify-content-between">
            <h6 class="font-weight-bolder mb-25">

              {{ comment.user.name }} {{ comment.user.lastname }}
            </h6>
            <small class="text-muted">{{ comment.created_at }}</small>
          </div>
          <b-card-text>
            {{ comment.comment }}
          </b-card-text>
          <!-- <b-link>
            <div class="d-inline-flex align-items-center">
              <feather-icon icon="CornerUpLeftIcon" size="18" class="mr-50" />
              <span>Reply</span>
            </div>
          </b-link> -->
        </b-media-body>
      </b-media>
      <hr />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BAvatar,
  BCardText,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";

import axiosCard from "@/services/board/card";

export default {
  components: {
    BCard,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BAvatar,
    BCardText,
    BButton,
    BFormTextarea,
  },
  props: {
    selectedCard: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      comment: "",
    };
  },
  computed: {
    sortComments() {
      return this.selectedCard.card_comments.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
    },
  },
  methods: {
    sendComment() {
      const data = {
        action: "card_add_comment",
        comment: this.comment,
        status: true,
      };
      axiosCard.cardActions(this.selectedCard.id, data).then(({ registro }) => {
        const dataComment = registro.data
        const data = {
          ...registro.data,
          user: {
            name: dataComment.user_name,
            lastname: dataComment.user_lastname,
            avatar: dataComment.avatar,
          },
        }
        this.selectedCard.card_comments.push(data);
        this.comment = "";
      });
    },
  },
};
</script>

<style></style>
