<template>
  <b-card no-body>
    <b-card-header class="pb-50 cursor-pointer" style="display: block;" @click="visible = !visible">
      <div class="d-flex justify-content-between">
        <h5>{{ $t("Filters") }}</h5>
        <feather-icon :icon="visible ? 'ArrowDownIcon' : 'ArrowUpIcon'" />
      </div>
    </b-card-header>
    <b-collapse v-model="visible">
      <b-card-body>
        <b-row>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>Start date</label>
            <flat-pickr v-model="filters.card_start" :config="fConfig" class="form-control" placeholder="MM-DD-YYYY" />
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>End date</label>
            <flat-pickr v-model="filters.card_end" :config="uConfig" class="form-control" placeholder="MM-DD-YYYY" />
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>Patient name</label>
            <b-form-input v-model.trim="filters.patient_name" @keyup.enter="searchFilter" />
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>Patient dob</label>
            <flat-pickr v-model="filters.patient_dob" :config="fConfig" class="form-control" placeholder="MM-DD-YYYY" />
          </b-col>

          <b-col cols="12" class="mb-md-0 mb-2 mt-2" align="end">
            <b-button variant="warning" @click="resetFilter" class="mr-1">
              {{ $t("Clear") }}
            </b-button>
            <b-button variant="primary" @click="searchFilter" :disabled="loading">
              <span v-if="!loading">{{ $t("Search") }}</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BCollapse,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

import SpinnerLoading from "@/components/SpinnerLoading";
import { number } from "echarts/lib/export";
import { fromConfig, untilConfig } from "@/mixins/configFormatCal";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    BButton,
    BCollapse,
    vSelect,
    flatPickr,
    SpinnerLoading,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stateOptions: [],
      filters: {
        card_start: null,
        card_end: null,
        patient_name: null,
        patient_dob: null,
        card_description: null,
      },
      visible: false,
      fConfig: fromConfig({ dateFormat: "m-d-Y", }),
      uConfig: untilConfig({ dateFormat: "m-d-Y", }),
    };
  },
  methods: {
    searchFilter() {
      const filter = this.filters;
      let cont = 0;
      for (const iterator in filter) {
        if (this.filters[iterator] == "" || this.filters[iterator] == null) {
          cont++;
        }
      }
      // if (Object.keys(filter).length == cont) this.$emit("filterPage", false);
      // else this.$emit("filterPage", true);
      this.$emit("searchFilter", JSON.parse(JSON.stringify(this.filters)));
    },
    resetFilter() {
      const filter = JSON.parse(JSON.stringify(this.filters));
      for (const iterator in filter) {
        this.filters[iterator] = null;
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.flatpickr-wrapper {
  // width: 50% !important;
  display: block;
}
</style>