<template>
  <b-sidebar
    :visible="showCardModal"
    id="sidebar-1"
    right
    backdrop
    width="70vw"
    z-index="1500"
    @hidden="closeCardModal"
    bg-variant="white"
  >
    <b-card class="p-0 m-0" body-class="pt-0">
      <div v-if="loading">
        <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          rounded="lg"
          class="loading-overlay h-100 mt-4"
        >
        </b-overlay>
      </div>
      <section v-else>
        <b-row class="justify-content-between">
          <b-col md="6">
            <div class="d-flex flex-column">
              <div class="d-flex align-items-end mb-0">
                <h2 class="mb-0 font-weight-bold" v-show="!isInputTitle">
                  {{ selectedCard.title }}
                </h2>
                <!-- editor titulo -->
                <b-form-input
                  v-show="isInputTitle"
                  v-model="selectedCard.title"
                  ref="inputTitle"
                  class="border-0 shadow-none font-weight-bold pb-0 pl-0 w-auto"
                  style="font-size: 1.714rem"
                  @blur="updateCardTitle"
                />
                <b-button v-show="!isInputTitle" variant="transparent">
                  <feather-icon
                    class="cursor-pointer text-primary"
                    icon="Edit2Icon"
                    size="13"
                    v-b-tooltip.hover.top="'Editar'"
                    @click="toggleInputTitle"
                  />
                </b-button>
                <!--  -->
              </div>
              <div>
                <b-badge
                  pill
                  class="my-1"
                  v-for="(label, index) in selectedCard.labels"
                  :key="index"
                  :style="{ 'background-color': label.color }"
                >
                  {{ label.name }}
                </b-badge>
                <b-badge
                  pill
                  id="popover-reactive-1"
                  class="my-1 text-dark border-dark cursor-pointer"
                  variant="white"
                  ref="buttonLabel"
                >
                  Add label
                </b-badge>
                <LabelAdd v-if="selectedCard" :selectedCard="selectedCard" />
              </div>
            </div>
          </b-col>
          <b-col md="6">
            <div class="d-flex flex-column align-items-end">
              <div class="d-flex">
                <!-- <b-button variant="primary" class="btn-icon rounded-circle">
                  <feather-icon
                    class="cursor-pointer text-white margin-icon"
                    icon="PhoneIcon"
                    size="18"
                    v-b-tooltip.hover.top="'Llamar'"
                  />
                </b-button> -->
                <b-button
                  variant="primary"
                  class="btn-icon rounded-circle mx-1"
                  @click.stop="getPhoneSelected"
                >
                  <feather-icon
                    class="cursor-pointer text-white margin-icon"
                    icon="MessageSquareIcon"
                    size="18"
                    v-b-tooltip.hover.top="'Enviar mensaje'"
                  />
                </b-button>
                <MessageAdd
                  v-if="isSelectedPhoneCard"
                  :selectedCard="selectedCard"
                  @closeCardNewBoard="closeCardNewBoard"
                />
                <!-- <b-button variant="primary" class="btn-icon rounded-circle">
                  <feather-icon
                    class="cursor-pointer text-white margin-icon"
                    icon="CheckIcon"
                    size="18"
                    v-b-tooltip.hover.top="'Completar'"
                  />
                </b-button> -->
              </div>
              <div class="mt-1">
                <span class="text-muted"> ID: {{ selectedCard.id }} </span>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row class="px-2">
          <b-tabs class="w-100">
            <b-tab active title="General">
              <GeneralCard :selectedCard="selectedCard" />
            </b-tab>
            <b-tab title="Document">
              <DocumentCard :selectedCard="selectedCard" />
            </b-tab>
            <b-tab title="Comments">
              <CommentCard :selectedCard="selectedCard" />
            </b-tab>
          </b-tabs>
        </b-row>
      </section>
    </b-card>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BButton,
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardBody,
  BTabs,
  BTab,
  BOverlay,
  VBToggle,
  BBadge,
  BFormInput,
  VBTooltip,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";

import axiosCard from "@/services/board/card";

import GeneralCard from "./sidebar-info/GeneralCard.vue";
import DocumentCard from "./sidebar-info/DocumentCard.vue";
import ActivityCard from "./sidebar-info/ActivityCard.vue";
import CommentCard from "./sidebar-info/CommentCard.vue";
import LabelAdd from "./sidebar-info/LabelAdd.vue";
import MessageAdd from "./sidebar-info/MessageAdd.vue";

export default {
  components: {
    BSidebar,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BButton,
    BOverlay,
    BTabs,
    BTab,
    BBadge,
    BFormInput,
    VBToggle,
    VBTooltip,

    DocumentCard,
    ActivityCard,
    GeneralCard,
    CommentCard,
    LabelAdd,
    MessageAdd,
  },
  directives: { "b-toggle": VBToggle, "b-tooltip": VBTooltip, Ripple },
  props: {
    cardId: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.getCardInformation();
  },
  data() {
    return {
      showCardModal: true,
      selectedCard: null,
      loading: true,
      colorCard: null,
      isInputTitle: false,

      isSelectedPhoneCard: false,
    };
  },
  methods: {
    getCardInformation() {
      this.loading = true;
      axiosCard.cardGet(this.cardId).then(({ registro }) => {
        this.selectedCard = registro;
        this.loading = false;
        //pintar la card con el color que trae el registro
      });
    },
    closeCardModal() {
      this.showCardModal = false;
      this.$emit("closeSidebarCardBoard", false);
    },
    updateCardTitle() {
      this.isInputTitle = false;
      const data = {
        title: this.selectedCard.title,
      };
      axiosCard.cardUpdate(this.selectedCard.id, data); // Make sure to import and use axiosCard appropriately
    },
    toggleInputTitle() {
      this.isInputTitle = true;
      this.$nextTick(() => {
        this.$refs.inputTitle.focus();
      });
    },
    getPhoneSelected() {
      this.isSelectedPhoneCard = true;
    },
    closeCardNewBoard() {
      this.isSelectedPhoneCard = false;
    },
  },
};
</script>

<style></style>
