<template>
  <div>
    <div
      class="move-card"
      @mousedown="startDrag"
      @mouseup="endDrag"
      @mouseleave="endDrag"
      @closeModalCardBoard="changeSelectedCard"
      :class="{ 'is-dragging': isDragging }"
    >
      <b-card
        class="mb-0"
        @click="getModalInfo(body.id)"
        body-class="padding-card-board"
      >
        <b-card-body class="p-0">
          <b-card-title class="d-flex justify-content-between">
            <div>
              <h4>{{ body.title }}</h4>
              <p class="text-muted small mb-0">
                <!-- solo mostrar un pedazo de la descripcion -->
                {{ body.description.slice(0, 60) + "..." }}
              </p>
              <b-badge
                pill
                class="font-small-2"
                v-for="label in body.labels"
                :key="label.pivot.label_id"
                :style="{ 'background-color': label.color }"
              >
                {{ label.name }}
              </b-badge>
            </div>
            <div class="d-flex flex-column">
              <!-- esta accion no deberia activar la padre -->
              <feather-icon
                class="cursor-pointer text-primary margin-icon"
                icon="MessageSquareIcon"
                size="18"
                v-b-tooltip.hover.left="'Enviar mensaje'"
                @click.stop="getPhoneSelected(body)"
              />
              <MessageAdd
                v-if="isSelectedPhoneCard"
                :selectedCard="selectedPhoneCard"
                @closeCardNewBoard="closeCardNewBoard"
              />
              <!-- <feather-icon
                class="cursor-pointer text-primary margin-icon"
                icon="CheckIcon"
                size="18"
                v-b-tooltip.hover.left="'Completar'"
              /> -->
            </div>
          </b-card-title>
        </b-card-body>
        <b-card-footer class="text-muted small mb-0 pb-0 px-0">
          <div class="d-flex justify-content-between">
            <div>
              <feather-icon icon="ClockIcon" size="18" />
              {{ body.date }}
            </div>
            <b-avatar
              size="30px"
              v-b-tooltip.hover.top="body.agent.name"
              :src="body.agent.avatar"
              class="pull-up"
            />
          </div>
        </b-card-footer>
      </b-card>
    </div>
    <SidebarCard
      v-if="isSidebarActive"
      :cardId="selectedCard"
      @closeSidebarCardBoard="changeSelectedCard"
    />
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  BCardText,
  BCardFooter,
  BIcon,
  BAvatarGroup,
  BAvatar,
  BBadge,
  VBTooltip,
} from "bootstrap-vue";
import SidebarCard from "./SidebarCard.vue";
import MessageAdd from "./sidebar-info/MessageAdd.vue";

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardText,
    BCardFooter,
    BIcon,
    BAvatarGroup,
    BAvatar,
    BBadge,
    SidebarCard,
    MessageAdd,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    body: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isDragging: false,
      selectedCard: null,
      selectedPhoneCard: null,
      isSidebarActive: false,
      isSelectedPhoneCard: false,
      avatars: [
        {
          userImg: require("@/assets/images/portrait/small/avatar-s-1.jpg"),
        },
        {
          userImg: require("@/assets/images/portrait/small/avatar-s-2.jpg"),
        },
        {
          userImg: require("@/assets/images/portrait/small/avatar-s-3.jpg"),
        },
      ],
    };
  },
  methods: {
    getModalInfo(id) {
      this.isSidebarActive = true;
      this.$emit("noMoveCard", true);
      this.selectedCard = id;
    },
    changeSelectedCard() {
      this.selectedCard = null;
      this.$emit("noMoveCard", false);
      this.$emit("closeSidebarCardBoard", false);
      this.isSidebarActive = false;
    },
    startDrag() {
      this.isDragging = true;
    },
    endDrag() {
      this.isDragging = false;
    },
    getPhoneSelected(body) {
      this.selectedPhoneCard = body;
      this.isSelectedPhoneCard = true;
    },
    closeCardNewBoard() {
      this.isSelectedPhoneCard = false;
    },
  },
};
</script>

<style scoped>
/* que no puedan seleccionar y tenga cursor move */
.move-card {
  cursor: pointer;
}
.move-card.is-dragging {
  cursor: -webkit-grab;
  cursor: grab;
}
.margin-icon {
  margin-bottom: 5px;
}
.padding-card-board {
  padding: 0.7rem;
}
</style>
